import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import FirstAlertTheme from "../../../config/themes/FirstAlertTheme.mjs";

Vue.use(Vuetify);

export default new Vuetify({
    breakpoint : {
        mobileBreakpoint : 'sm',
    },
    theme: {
        themes: {
            dark: {
                primary: FirstAlertTheme.colours.blue, //'#00aaff',
                secondary: FirstAlertTheme.colours.darkBlue, //'#01124F',//'#272727',//'#424242',
                accent: FirstAlertTheme.colours.lightBlue, //'#76672a',
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFC107",
                gold: "#E5D08D",
                subtle: "#555555"
            },
            light: {
                primary: FirstAlertTheme.colours.blue, //'#00aaff',
                secondary: FirstAlertTheme.colours.darkBlue, //'#01124F',//'#272727',//'#424242',
                accent: FirstAlertTheme.colours.lightBlue, //'#76672a',
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFC107",
                gold: "#E5D08D",
                subtle: "#BBBBBB"
            }
        }
    }
});
