import L from "leaflet";
import rbush from "rbush";
import knn from "rbush-knn";
import app from "../../core/Cli_FirstAlertApp.mjs";
// function layerFactory(L) {

// -- L.DomUtil.setTransform from leaflet 1.0.0 to work on 0.0.7
//------------------------------------------------------------------------------
L.DomUtil.setTransform =
    L.DomUtil.setTransform ||
    function (el, offset, scale) {
        var pos = offset || new L.Point(0, 0);

        el.style[L.DomUtil.TRANSFORM] =
            (L.Browser.ie3d
                ? "translate(" + pos.x + "px," + pos.y + "px)"
                : "translate3d(" + pos.x + "px," + pos.y + "px,0)") +
            (scale ? " scale(" + scale + ")" : "");
    };
let I;
var CanvasIconLayer = (L.Layer ? L.Layer : L.Class).extend({
    //Add event listeners to initialized section.
    initialize: function (options) {
        I = this;
        L.setOptions(this, options);
        this._onClickListeners = [];
        this._onHoverListeners = [];
        // Setup animation ticker:
        this.alertedAssets = {};
        this.animId = L.Util.requestAnimFrame(this.onTick);
        this.ticker = 0;
    },

    setOptions: function (options) {
        L.setOptions(this, options);
        return this.redraw();
    },

    pixelDistance: 1, //Distance between 2 pixels relative to the map scale

    redraw: function () {
        this._redraw(true);
    },

    indexedMarkers: {},

    //Multiple layers at a time for rBush performance
    addMarkers: function (markers) {
        var self = this;
        self.indexedMarkers = {};
        var tmpMark = [];
        var tmpLatLng = [];
        //console.warn("Adding all the markers - map:",this._map,markers);
        if (!this._map) {
            return;
        }
        markers.forEach(function (marker) {
            if (!(marker.options.pane == "markerPane" && marker.options.icon)) {
                console.error("Layer isn't a marker");
                return;
            }
            if (marker.assetId) {
                marker.asset = app.state.assets[marker.assetId];
            }

            var latlng = marker.getLatLng();
            var isDisplaying = self._map.getBounds().contains(latlng);
            var s = self._addMarker(marker, latlng, isDisplaying);

            //Only add to Point Lookup if we are on map
            if (isDisplaying === true) tmpMark.push(s[0]);

            tmpLatLng.push(s[1]);

            // if (s[1].data && s[1].data.asset) {
            if (s[1].data && s[1].data.assetId) {
                // self.indexedMarkers[s[1].data.asset.id] = s[1]; //index it
                self.indexedMarkers[s[1].data.assetId] = s[1]; //index it
            }
        });
        //_markers contains Points of markers currently displaying on map
        if (!self._markers) self._markers = new rbush();

        //_latlngMarkers contains Lat\Long coordinates of all markers in layer.
        if (!self._latlngMarkers) {
            self._latlngMarkers = new rbush();
            self._latlngMarkers.dirty = 0;
            self._latlngMarkers.total = 0;
        }

        self._markers.load(tmpMark);
        self._latlngMarkers.load(tmpLatLng);
    },

    //Adds single layer at a time. Less efficient for rBush
    addMarker: function (marker) {
        var self = this;
        var latlng = marker.getLatLng();
        var isDisplaying = self._map.getBounds().contains(latlng);
        var dat = self._addMarker(marker, latlng, isDisplaying);

        //Only add to Point Lookup if we are on map
        if (isDisplaying === true) self._markers.insert(dat[0]);

        self._latlngMarkers.insert(dat[1]);
    },

    addLayer: function (layer) {
        if (layer.options.pane == "markerPane" && layer.options.icon)
            this.addMarker(layer);
        else console.error("Layer isn't a marker");
    },

    addLayers: function (layers) {
        this.addMarkers(layers);
    },

    removeLayer: function (layer) {
        this.removeMarker(layer, true);
    },

    removeMarker: function (marker, redraw) {
        var self = this;

        //If we are removed point
        if (marker["minX"]) marker = marker.data;

        var latlng = marker.getLatLng();
        var isDisplaying = self._map.getBounds().contains(latlng);

        var markerData = {
            minX: latlng.lng,
            minY: latlng.lat,
            maxX: latlng.lng,
            maxY: latlng.lat,
            data: marker
        };

        self._latlngMarkers.remove(markerData, function (a, b) {
            return a.data._leaflet_id === b.data._leaflet_id;
        });

        self._latlngMarkers.total--;
        self._latlngMarkers.dirty++;

        if (isDisplaying === true && redraw === true) {
            self._redraw(true);
        }
    },
    onTick(time) {
        //console.warn("Animation tick....",time);

        if (I.ticker > 1 && I._map) {
            //Redraw map:
           // I.redraw(true);
            //I._redraw(true);

            var now = Date.now();
            var oneHourAgo = now - 3600000;
            var oneMinuteAgo = now - 60000;

            var offsetX = I._fxcanvas.width / 3;
            var offsetY = I._fxcanvas.height / 3;
            // console.warn("Drawing alerted marker!",I.alertedAssets,time);

            var i = 0;
            var maxAnimCounter = 50;

            for (var assetId in I.alertedAssets) {
                if (i == 0) {
                    I._fxcontext.clearRect(
                        0,
                        0,
                        I._fxcanvas.width,
                        I._fxcanvas.height
                    );
                }

                var marker = I.alertedAssets[assetId];

                // Draw fx:
                //if (marker.assetId && app.state.assets[marker])

                if (marker.asset.lastAlerted > oneMinuteAgo) {
                    //var mX2 = ((this._canvas.width / 3) + ((width*(mapZoom > 16 ? 1 : mapZoom < 10 ? 0 : Math.log(mapZoom / 10))) / 2) ) + (pointPos.x - ((width*(mapZoom > 16 ? 1 : mapZoom < 10 ? 0 : Math.log(mapZoom / 10))) / 2));
                    var pointPos = I._map.latLngToContainerPoint(
                        marker.asset.location
                    );
                    if (
                        !marker.animCounter ||
                        marker.animCounter > maxAnimCounter
                    ) {
                        marker.animCounter = 0;
                    }

                    var radius = 10;
                    var lineWidth = 1;
                    var x = pointPos.x + offsetX;
                    var y = pointPos.y + offsetY;
                    //console.warn("Drawing alerted marker!",assetId,marker.asset.name,pointPos);

                    I._fxcontext.beginPath();
                    var alpha = 1 * (1 - marker.animCounter / maxAnimCounter);
                    //var alpha = 1;
                    I._fxcontext.fillStyle = `rgba(255,0,0,${alpha / 2})`;
                    // I._fxcontext.strokeStyle = "#FF0000ee";
                    I._fxcontext.strokeStyle = `rgba(255,0,0,${alpha})`;
                    I._fxcontext.lineWidth = lineWidth;
                    //I._fxcontext.opacity = 0.3;

                    I._fxcontext.arc(
                        x,
                        y,
                        radius + marker.animCounter,
                        0,
                        2 * Math.PI
                    );
                    I._fxcontext.stroke();
                    I._fxcontext.fill();
                    I._fxcontext.closePath();

                    I._fxcontext.beginPath();

                    I._fxcontext.strokeStyle = `rgba(255,0,0,${alpha / 1.33})`;
                    //I._fxcontext.lineWidth = lineWidth;
                    I._fxcontext.arc(
                        x,
                        y,
                        radius / 1.33 + marker.animCounter,
                        0,
                        2 * Math.PI
                    );
                    I._fxcontext.stroke();
                    I._fxcontext.closePath();

                    I._fxcontext.beginPath();
                    I._fxcontext.strokeStyle = `rgba(255,0,0,${alpha / 1.66})`;
                    //I._fxcontext.lineWidth = lineWidth;
                    I._fxcontext.arc(
                        x,
                        y,
                        radius / 1.66 + marker.animCounter,
                        0,
                        2 * Math.PI
                    );
                    I._fxcontext.stroke();
                    I._fxcontext.closePath();

                    marker.animCounter++;
                } else {
                    // Remove it from the list:
                    delete I.alertedAssets[assetId];
                }
                i++;
            }

            I.ticker = 0;
        }

        I.animId = L.Util.requestAnimFrame(I.onTick);
        I.ticker++;
    },

    onAdd: function (map) {
        this._map = map;

        if (!this._canvas) this._initCanvas();

        if (this.options.pane) {
            this.getPane().appendChild(this._canvas);
            this.getPane().appendChild(this._fxcanvas);
        } else {
            map._panes.overlayPane.appendChild(this._canvas);
            map._panes.overlayPane.appendChild(this._fxcanvas);
        }

        map.on("moveend", this._reset, this);
        map.on("resize", this._reset, this);

        // map.on("dblclick", this._executeListeners, this);
        map.on("click", this._executeListeners, this);
        map.on("mousemove", this._executeListeners, this);

        //map.on("zoom", this._executeListeners, this);
        if (map.options.zoomAnimation) {
            // && L.Browser.any3d) {
            //if (map._zoomAnimated && L.Browser.any3d) {
            //events.zoomanim =  this._animateZoom;
            //map.on("zoomstart", this._animateZoom, this);
            map.on("zoomanim", this._animateZoom, this);
        }
    },

    // -- L.DomUtil.setTransform from leaflet 1.0.0 to work on 0.0.7
    //------------------------------------------------------------------------------
    _setTransform: function (el, offset, scale) {
        var pos = offset || new L.Point(0, 0);

        el.style[L.DomUtil.TRANSFORM] =
            (L.Browser.ie3d
                ? "translate(" + pos.x + "px," + pos.y + "px)"
                : "translate3d(" + pos.x + "px," + pos.y + "px,0)") +
            (scale ? " scale(" + scale + ")" : "");
    },
    _animLastZoom: 18,
    _animateZoom: function (event) {
        //console.warn(event,this._map.getBounds(),`lastZoom:${this._animLastZoom}`);

        var thirdWidth = this._canvas.width / 3;
        var thirdHeight = this._canvas.height / 3;
        var offsetBounds = [
            this._map.containerPointToLatLng([
                this._canvas.width - thirdWidth,
                -thirdHeight
            ]),

            this._map.containerPointToLatLng([
                -thirdWidth,
                this._canvas.height - thirdHeight
            ])
        ];

        var scale = this._map.getZoomScale(event.zoom);
        var offset = this._map._latLngBoundsToNewLayerBounds(
            L.latLngBounds(offsetBounds),
            //this._map.getBounds(),
            event.zoom,
            event.center
        ).min;

        //this._map.getPixelWorldBounds(<Number> zoom?)

        // offset.x *=0.2;
        // offset.y *=0.1;
        // if (event.zoom > this._animLastZoom){ // Current zoom is bigger - zoom out
        //     console.warn("Zooming IN?",event.zoom,'>',this._animLastZoom);
        //     offset.x -= this._canvas.width/6;
        //     offset.y -= this._canvas.height/6;
        // } else { // Current zoom is smaller - zoom in
        //     console.warn("Zooming OUT?",event.zoom,'<',this._animLastZoom);
        //     offset.x -= this._canvas.width/6;
        //     offset.y -= this._canvas.height/6;
        // }
        this._animLastZoom = event.zoom;

        //console.warn("Offset:", offset, event.center);
        L.DomUtil.setTransform(this._canvas, offset, scale);
        L.DomUtil.setTransform(this._fxcanvas, offset, scale);
        //L.DomUtil.setTransform(this._canvas, offset);
    },

    // _animateZoom: function (e) {
    //     var scale = this._map.getZoomScale(e.zoom);
    //     // -- different calc of animation zoom  in leaflet 1.0.3 thanks @peterkarabinovic, @jduggan1
    //     var offset = L.Layer ? this._map._latLngBoundsToNewLayerBounds(this._map.getBounds(), e.zoom, e.center).min :
    //                            this._map._getCenterOffset(e.center)._multiplyBy(-scale).subtract(this._map._getMapPanePos());

    //     L.DomUtil.setTransform(this._canvas, offset, scale);

    // },

    onRemove: function (map) {
        if (this.options.pane) {
            this.getPane().removeChild(this._canvas);
            this.getPane().removeChild(this._fxcanvas);
        } else {
            map.getPanes().overlayPane.removeChild(this._canvas);
            map.getPanes().overlayPane.removeChild(this._fxcanvas);
        }

        map.off("click", this._executeListeners, this);
        map.off("mousemove", this._executeListeners, this);

        map.off("moveend", this._reset, this);
        map.off("resize", this._reset, this);
        if (map.options.zoomAnimation) {
            //} && L.Browser.any3d) {
            //map.off('zoomstart', this._animateZoom, this);
            map.off("zoomanim", this._animateZoom, this);
        }
    },

    addTo: function (map) {
        // console.warn("Map to add to?", map);
        map.addLayer(this);
        return this;
    },

    clearLayers: function () {
        this._latlngMarkers = null;
        this._markers = null;
        this._redraw(true);
    },

    _addMarker: function (marker, latlng, isDisplaying) {
        var self = this;
        //Needed for pop-up & tooltip to work.
        marker._map = self._map;

        //_markers contains Points of markers currently displaying on map
        if (!self._markers) self._markers = new rbush();

        //_latlngMarkers contains Lat\Long coordinates of all markers in layer.
        if (!self._latlngMarkers) {
            self._latlngMarkers = new rbush();
            self._latlngMarkers.dirty = 0;
            self._latlngMarkers.total = 0;
        }

        L.Util.stamp(marker);

        var pointPos = self._map.latLngToContainerPoint(latlng);
        var iconSize = marker.options.icon.options.iconSize;

        if (marker.type == "context") {
            iconSize = [marker.width, marker.height];
            //console.warn(`Adding context marker for ${marker.name}:`,marker.width,iconSize);
        }

        var adj_x = iconSize[0] / 2;
        var adj_y = iconSize[1] / 2;
        var ret = [
            {
                minX: pointPos.x - adj_x,
                minY: pointPos.y - adj_y,
                maxX: pointPos.x + adj_x,
                maxY: pointPos.y + adj_y,
                name: marker.name,
                data: marker
            },
            {
                minX: latlng.lng,
                minY: latlng.lat,
                maxX: latlng.lng,
                maxY: latlng.lat,
                name: marker.name,
                data: marker
            }
        ];
        if (marker.type == "context") {
            //console.warn(`Context marker for ${marker.name} ret:`,ret);
        }

        self._latlngMarkers.dirty++;
        self._latlngMarkers.total++;

        //Only draw if we are on map
        if (isDisplaying === true) self._drawMarker(marker, pointPos, ret[0]);

        return ret;
    },

    _drawMarker: function (marker, pointPos, ret) {
        var self = this;
        if (marker.assetId) {
            marker.asset = app.state.assets[marker.assetId];
        }
        if (!marker.asset) {
            // console.error(`Invalid asset detected in marker: `,marker);
            return;
        }
        if (marker.asset.type != "context") {
            //console.warn("MArker last alerted??",marker.name,marker.asset);
            var oneHourAgo = Date.now() - 3600000; //In the last hour
            var oneMinuteAgo = Date.now() - 60000; //In the last hour
            if (
                marker.asset.lastAlerted &&
                marker.asset.lastAlerted > oneMinuteAgo
            ) {
                // Alert still fresh - add it to the marker list:
                I.alertedAssets[marker.asset.id] = marker;
                // console.warn("Marker:",marker);
            }
        }

        if (!this._imageLookup) this._imageLookup = {};
        if (!pointPos) {
            if (marker._latlng && marker.asset && marker.asset.location) {
                marker._latlng.lat = marker.asset.location.lat;
                marker._latlng.lng = marker.asset.location.lng;
            }
            //if (marker.asset && marker.asset.location){
            //    pointPos = self._map.latLngToContainerPoint(marker.asset.location);
            //}else {
            pointPos = self._map.latLngToContainerPoint(marker.getLatLng());
            //}
        }

        var iconUrl = marker.options.icon.options.iconUrl;

        if (marker.canvas_img) {
            // self.beforeMarkerDraw(marker,pointPos);
            self._drawImage(marker, pointPos);
            //this._context.fillStyle = '#FF000066';
            //console.warn("RET:",ret);
            //this._context.fillRect(ret.minX,ret.minY,Math.abs(ret.maxX-ret.minX),Math.abs(ret.maxY-ret.minY));

            // self.afterMarkerDraw(marker,pointPos);
        } else {
            if (self._imageLookup[iconUrl]) {
                marker.canvas_img = self._imageLookup[iconUrl][0];

                if (self._imageLookup[iconUrl][1] === false) {
                    self._imageLookup[iconUrl][2].push([marker, pointPos]);
                } else {
                    //self.beforeMarkerDraw(marker,pointPos);
                    self._drawImage(marker, pointPos);
                    //self.afterMarkerDraw(marker,pointPos);
                }
            } else {
                var i = new Image();
                i.src = iconUrl;
                marker.canvas_img = i;
                //Image,isLoaded,marker\pointPos ref
                self._imageLookup[iconUrl] = [i, false, [[marker, pointPos]]];

                i.onload = function () {
                    self._imageLookup[iconUrl][1] = true;
                    self._imageLookup[iconUrl][2].forEach(function (e) {
                        //self.beforeMarkerDraw(marker,pointPos);
                        self._drawImage(e[0], e[1]);
                        //self.afterMarkerDraw(marker,pointPos);
                    });
                };
            }
        }
    },

    // Overrides
    beforeMarkerDraw: function (marker) {},
    afterMarkerDraw: function (marker) {},
    //-------------

    _drawImage: function (marker, pointPos) {
        const I = this;
        var doDraw = true;
        this.beforeMarkerDraw(marker, pointPos);

        // var mapScale = this._map.getZoomScale(this._map.getZoom());
        var mapZoom = this._map.getZoom();
        //console.warn("ZOOMZOOM:",mapZoom);
        //var mapZoomMax = this._map.getMaxZoom();
        //var mapZoomMin = this._map.getMinZoom();

        var options = marker.options.icon.options;
        options.iconAnchor = [0, 0];

        var width = marker.canvas_img.width || options.iconSize[0];
        var height = marker.canvas_img.height || options.iconSize[1];

        //console.warn({w:width,h:height,iconAnchor:options.iconAnchor});

        var scaleWidth = width;
        var scaleHeight = height;
        if (marker.type == "context") {
            var thresholdZoom = mapZoom / 10;
            var zoomScale =
                mapZoom > 16 ? 1 : mapZoom < 10 ? 0 : Math.log(thresholdZoom);
            //(mapZoom / (thresholdZoom^mapZoom));
            //zoomScale = ();//y = a*(x*x)+(b*x)+c
            //zoomScale =  Math.log(mapZoom) / Math.log(thresholdZoom);/*  */
            //zoomScale = zoomScale > 1 ? 1 : zoomScale;
            if (zoomScale < 0.1) {
                doDraw = false;
            }
            //console.warn("Scaling context label:", mapZoom, zoomScale);

            scaleWidth *= zoomScale;
            scaleHeight *= zoomScale;
        }
        if (doDraw) {
            var halfScaleWidth = scaleWidth / 2;
            var halfScaleHeight = scaleHeight / 2;
            //var topLeft = this._map.containerPointToLayerPoint([this._canvas.width/2,this._canvas.height/2]);
            //L.DomUtil.setPosition(this._canvas, topLeft);
            //var topLeft = [this._canvas.width/4,this._canvas.height/4];
            //var topLeft = [0,0];
            var topLeft = {
                x: this._canvas.width / 3,
                y: this._canvas.height / 3
            };
            //console.warn(`Marker ${marker.name} topLeft`,topLeft);
            var cX = pointPos.x + options.iconAnchor[0] - halfScaleWidth;
            var cY = pointPos.y + options.iconAnchor[1] - halfScaleHeight;

            var leftOffset = topLeft.x + halfScaleWidth;
            var topOffset = topLeft.y + halfScaleHeight;

            var mX = leftOffset + cX; //Marker middle X
            var mY = topOffset + cY; // Marker middle Y

            // this._context.drawImage(
            //     marker.canvas_img,
            //     topLeft.x + cX,
            //     topLeft.y + cY,
            //     scaleWidth,
            //     scaleHeight
            // );

            if (marker.asset) {
                // Refresh asset:
                marker.asset = app.state.assets[marker.asset.id];//marker.asset.nvr]
                // var drawMarker = true;
                //var drawMarkerMute = false;
                // Refresh asset data from main source:
//console.warn("MARKER:",marker.asset.name,marker.asset);
                var enabled = true; //marker.asset.status && marker.asset.status.enabled;
                var offline = false; //marker.asset.status && marker.asset.status.online;
                var muted = false; //marker.asset.status && marker.asset.status.muted;
                var hidden = false; //marker.asset.status && marker.asset.status.hidden;

                var nvr = false;
                if (marker.asset.nvr) {
                    // If nvr is muted then this device is muted too - override
                    // get nvr:
                    nvr = app.state.assets[marker.asset.nvr];
                }
                if (marker.asset.status) {

                    enabled = marker.asset.status.enabled ? 1 : 0;
                    offline = marker.asset.status.online ? 0 : 1;
                    muted = marker.asset.status.muted ? 1 : 0;
                    hidden = marker.asset.status.hidden ? 1 : 0;
                    // console.warn(marker.asset.name + "Status:",marker.asset.status);
                    //console.warn(marker.asset.name + " offline? ",offline);
                    //drawMarker = !marker.asset.status.hidden;
                    //drawMarkerMute = marker.asset.status.muted; // If this camera is muted then show it

                    if (nvr && nvr.status) {
                        if (!nvr.status.enabled) {
                            enabled = false;
                        }
                        if (nvr.status.muted) {
                            muted = true;
                        }
                        //console.warn("NVR STATUS:",nvr.status.online);
                        if (!nvr.status.online) {
                            offline = true;
                        }
                    }
                }

                // console.warn(
                //     `asset:${marker.asset.name}=hidden:${
                //         hidden ? 1 : 0
                //     },enabled:${enabled ? 1 : 0}`
                // );
                //console.warn(marker.asset);

                if (!hidden) {
                    if (marker.asset.selected) {
                        //console.warn("Marker asset:",marker);
                        this._context.beginPath();
                        // this._context.strokeStyle="#00ffff";
                        // this._context.strokeStyle="#00ff00";
                        this._context.strokeStyle = "#00aaff";
                        // this._context.strokeRect(topLeft.x + cX,topLeft.y + cY,scaleWidth,scaleHeight);
                        // this._context.strokeRect(topLeft.x+cX-3,topLeft.y+cY-3,scaleWidth+3,scaleHeight+3);

                        // this._context.arc(pointPos.x,pointPos.y, 30, 0, 2 * Math.PI, false);
                        var radius = marker.canvas_img.width / 2 + 3;

                        // var grd = this._context.createRadialGradient(topLeft.x + cX+scaleWidth/2,topLeft.y + cY+scaleHeight/2,radius-6, topLeft.x + cX+scaleWidth/2,topLeft.y + cY+scaleHeight/2, radius+6);
                        // grd.addColorStop(0, "#00aaff");
                        // grd.addColorStop(1, "white");

                        // Fill with gradient
                        // this._context.strokeStyle = grd;
                        //radius += Math.random()*3;
                        this._context.lineWidth = 3;
                        this._context.arc(
                            mX,
                            mY,
                            radius,
                            0,
                            2 * Math.PI,
                            false
                        );
                        this._context.stroke();
                    }
                    //console.warn("TYPE", marker.asset);

                    if (
                        marker.asset.type == "camera" ||
                        (marker.asset.type == "ipcamera" && mapZoom > 14)
                    ) {
                        //if (!marker.asset.spreadCoords) {
                        //function recalcSpreadCone(marker) {
                        //console.warn("ASSET SPREAD:",marker.asset);

                        //console.warn("Leaflet version:",L.version);
                        marker.asset.info.spread =
                            marker.asset.info.spread || {};

                        marker.asset.info.spread.angle =
                            marker.asset.info.spread.angle || 45; //default to 45
                        marker.asset.info.spread.color =
                            marker.asset.info.spread.color || "#ffffff";
                        marker.asset.info.spread.fillColor =
                            marker.asset.info.spread.color || "#999999";

                        marker.asset.info.bearing =
                            marker.asset.info.bearing || 0;
                        // marker.asset.bearing = 0;

                        var halfangle = marker.asset.info.spread.angle / 2;

                        var angle1 = marker.asset.info.bearing - halfangle;
                        var angle2 = marker.asset.info.bearing + halfangle;
                        //console.warn("ASSET LOCATION:",marker.asset.location,
                        //angle1,
                        //marker.asset.info);
                        marker.asset.info.spreadCoords = [
                            [
                                marker.asset.location.lat,
                                marker.asset.location.lng
                            ],
                            L.GeometryUtil.destination(
                                marker.asset.location,
                                angle1,
                                marker.asset.info.spread.range
                            ),
                            L.GeometryUtil.destination(
                                marker.asset.location,
                                angle2,
                                marker.asset.info.spread.range
                            )
                        ];

                        var spreadCoords = [
                            { x: mX, y: mY },
                            I._map.latLngToContainerPoint(
                                marker.asset.info.spreadCoords[1]
                            ),
                            I._map.latLngToContainerPoint(
                                marker.asset.info.spreadCoords[2]
                            )
                        ];
                        if (marker.asset.info.spread.color == "red") {
                            marker.asset.info.spread.color = "#ff0000";
                        }
                        if (marker.asset.info.spread.color == "green") {
                            marker.asset.info.spread.color = "#00ff00";
                        }
                        if (marker.asset.info.spread.color == "blue") {
                            marker.asset.info.spread.color = "#0000ff";
                        }

                        // console.warn( marker.asset.spread.fillColor);
                        var isHoverAsset =
                            this._hoverAssetId == marker.asset.id;
                        var fillColor =
                            marker.asset.info.spread.color +
                            (isHoverAsset ? "66" : "11");
                        var strokeColor =
                            marker.asset.info.spread.color +
                            (isHoverAsset ? "ee" : "aa");

                        this._context.beginPath();
                        this._context.moveTo(mX, mY); // Go to centre of camera icon
                        this._context.fillStyle = fillColor; //marker.asset.spread.fillColor;
                        this._context.strokeStyle = strokeColor; //marker.asset.spread.color;
                        this._context.lineWidth = 1;
                        this._context.lineTo(
                            topLeft.x + spreadCoords[1].x,
                            topLeft.y + spreadCoords[1].y
                        ); // From start to 2
                        this._context.lineTo(
                            topLeft.x + spreadCoords[2].x,
                            topLeft.y + spreadCoords[2].y
                        ); // From start to 2
                        // this._context.lineTo(mX - 50, mY - 50);
                        // this._context.lineTo(mX + 50, mY - 50);
                        //this._context.moveTo(mX + 50, mY - 50);
                        this._context.lineTo(mX, mY);

                        // this._context.lineTo(
                        //     marker.asset.spreadCoords[2].x,
                        //     marker.asset.spreadCoords[2].y
                        // ); // 2 to 3
                        // this._context.lineTo(
                        //     marker.asset.spreadCoords[0].x,
                        //     marker.asset.spreadCoords[0].y
                        // ); // Back to start
                        this._context.closePath();

                        this._context.stroke();
                        this._context.fill();
                    }

                    var canvas_img = marker.canvas_img;

                    if (!enabled && marker.asset.type != "context") {
                        this._context.globalAlpha = 0.4;
                        // if (marker.canvas_img_red) {
                        //     // this._context.globalAlpha = 1;
                        //     canvas_img = marker.canvas_img_red;
                        // }
                    } else {
                        this._context.globalAlpha = 1;
                    }
                    if (offline && marker.canvas_img_red) {
                        // this._context.globalAlpha = 1;
                        canvas_img = marker.canvas_img_red;
                    }
                    // console.warn("DRAWING THIS THING!",canvas_img,marker.asset.name);
                    if (mapZoom < 16) {
                        scaleWidth /= 2;
                        scaleHeight /= 2;
                        cX += scaleWidth / 2;
                        cY += scaleHeight / 2;
                    }
                    this._context.drawImage(
                        // marker.canvas_img,
                        canvas_img,
                        topLeft.x + cX,
                        topLeft.y + cY,
                        scaleWidth,
                        scaleHeight
                    );

                    if (muted) {
                        this._context.drawImage(
                            // marker.canvas_img,
                            marker.canvas_img_mute,
                            topLeft.x + cX,
                            topLeft.y + cY,
                            scaleWidth,
                            scaleHeight
                        );
                    }

                    this._context.globalAlpha = 1;

                    //if (marker.showLabel){
                    if (marker.type != "context" && mapZoom > 14) {
                        var fillColor = "#ffffff66"; //marker.asset.spread.color + "11";
                        var shadowColor = "#00000000"; //marker.asset.spread.color + "aa";

                        this._context.beginPath();
                        if (this._hoverAssetId == marker.asset.id) {
                            this._context.fillStyle = "#ffffffff"; //marker.asset.spread.fillColor;
                            this._context.shadowColor = "black";
                        } else {
                            this._context.fillStyle = fillColor; //marker.asset.spread.fillColor+'99';//"#ffffff99";
                            this._context.shadowColor = shadowColor; //marker.asset.spread.fillColor+'99';//"rgba(0,0,0,0.3)";
                        }
                        this._context.textAlign = "center";
                        this._context.shadowOffsetX = 1;
                        this._context.shadowOffsetY = 1;
                        this._context.shadowBlur = 4;
                        this._context.fillText(
                            `${marker.asset.name}`,
                            topLeft.x + cX + halfScaleWidth,
                            topLeft.y + cY
                        );
                        this._context.closePath();
                        //this._context.fillText(`${marker.asset.name}`,topLeft.x + cX+halfScaleWidth+1,topLeft.y + cY+1);
                        this._context.shadowOffsetX = 0;
                        this._context.shadowOffsetY = 0;
                        this._context.shadowColor = "transparent";

                        // this._context.fillStyle = "blue";
                    }

                    // Do "chips" - indicators:
                    // if (marker.asset.type!=='context' && marker.asset.alerts &&  marker.asset.alerts.new){
                    if (
                        marker.asset.type !== "context" &&
                        app.alerts.devices[marker.asset.id] &&
                        app.alerts.devices[marker.asset.id].newCount
                    ) {
                        // Add number:
                        var alertCount =
                            app.alerts.devices[marker.asset.id].newCount;
                        if (alertCount > 99) {
                            alertCount = "99+";
                        }
                        this._context.beginPath();
                        this._context.fillStyle = "#ff000066";
                        this._context.strokeStyle = "#ff0000";
                        this._context.lineWidth = 2;
                        this._context.arc(
                            mX + halfScaleWidth,
                            mY + halfScaleHeight,
                            halfScaleWidth / 2,
                            0,
                            2 * Math.PI,
                            false
                        );
                        this._context.stroke();
                        this._context.fill();
                        this._context.closePath();
                        this._context.beginPath();
                        this._context.fillStyle = "#ffffff";
                        this._context.shadowColor = "black";
                        this._context.textAlign = "center";
                        this._context.shadowOffsetX = 1;
                        this._context.shadowOffsetY = 1;
                        this._context.shadowBlur = 4;
                        this._context.fillText(
                            `${alertCount}`,
                            mX + halfScaleWidth,
                            mY + halfScaleHeight + 3
                        );
                        this._context.closePath();
                        this._context.shadowOffsetX = 0;
                        this._context.shadowOffsetY = 0;
                        this._context.shadowColor = "transparent";
                    }
                } // if Draw Marker
            }
        }

        this.afterMarkerDraw(marker, pointPos);
    },
    _hoverAssetId: "",

    _reset: function () {
        // var topLeft = this._map.containerPointToLayerPoint([0, 0]);
        // L.DomUtil.setPosition(this._canvas, topLeft);

        var size = this._map.getSize();
        var topLeft = this._map.containerPointToLayerPoint([-size.x, -size.y]); //this._canvas.width/2,this._canvas.height/2]);
        L.DomUtil.setPosition(this._canvas, topLeft);
        L.DomUtil.setPosition(this._fxcanvas, topLeft);

        this._canvas.width = size.x * 3;
        this._canvas.height = size.y * 3;
        this.syncFXCanvas();
        this._redraw();
    },

    resyncLatLng() {
        // Iterate the markers to update and resync their locations:
        for (var m in this.markers) {
        }
    },

    beforeRedraw: () => {},
    afterRedraw: () => {},

    _redraw: function (clear) {
        var self = this;
        // this.pixelDistance =
        //     this._map.distance(
        //         this._map.containerPointToLatLng([0, 0]),
        //         this._map.containerPointToLatLng([1, 0])
        //     );
        // console.warn("Refreshed pixel Distance",this.pixelDistance,"metres per pixel");

        if (clear)
            self._context.clearRect(
                0,
                0,
                self._canvas.width,
                self._canvas.height
            );
        self._fxcontext.clearRect(
            0,
            0,
            self._fxcanvas.width,
            self._fxcanvas.height
        );
        if (!self._map || !self._latlngMarkers) return;

        var tmp = [];

        this.beforeRedraw(self);

        //If we are 10% individual inserts\removals, reconstruct lookup for efficiency
        if (self._latlngMarkers.dirty / self._latlngMarkers.total >= 0.1) {
            self._latlngMarkers.all().forEach(function (e) {
                if (e.data.asset && e.data.asset.location) {
                    e.minY =
                        e.maxY =
                        e.data._latlng.lat =
                            e.data.asset.location.lat;
                    e.minX =
                        e.maxX =
                        e.data._latlng.lng =
                            e.data.asset.location.lng;
                    //console.warn("Updated latlng");
                }
                // {
                //     minX: latlng.lng,
                //     minY: latlng.lat,
                //     maxX: latlng.lng,
                //     maxY: latlng.lat,
                //     name: marker.name,
                //     data: marker
                // }
                tmp.push(e);
            });

            self._latlngMarkers.clear();
            self._latlngMarkers.load(tmp);
            self._latlngMarkers.dirty = 0;
            tmp = [];
        }

        var mapBounds = self._map.getBounds().pad(1);
        //var zoomLevel = self._map.getZoom();
        //console.warn("ZOOM:",zoomLevel);

        //Only re-draw what we are showing on the map.

        var mapBoxCoords = {
            minX: mapBounds.getWest(),
            minY: mapBounds.getSouth(),
            maxX: mapBounds.getEast(),
            maxY: mapBounds.getNorth()
        };

        self._latlngMarkers.search(mapBoxCoords).forEach(function (e) {
            //Readjust Point Map
            //console.warn("LATLNG?",e.data._latlng);
            if (e.data._latlng && e.data.asset && e.data.asset.location) {
                // console.warn("Updating latlong!",app.state.assets[e.data.asset.id]);
                e.data.asset = app.state.assets[e.data.asset.id];
                if (!e.data.asset || (e.data.asset && !e.data.asset.location)) {
                    // app.console.errorMsg(
                    //     `Error trying to assign marker location for asset:`
                    // );
                    // console.error(e.data.asset);
                } else {
                    e.minY =
                        e.maxY =
                        e.data._latlng.lat =
                            e.data.asset.location.lat; // = app.state.assets[e.data.asset.id].location.lat;
                    e.minX =
                        e.maxX =
                        e.data._latlng.lng =
                            e.data.asset.location.lng; // = app.state.assets[e.data.asset.id].location.lng;
                }

                // self._latlngMarkers.remove(e);
                // self._latlngMarkers.insert(e);
            }
            var pointPos = self._map.latLngToContainerPoint(e.data.getLatLng());

            // if (e.data.asset && e.data.asset.location){
            //     pointPos = self._map.latLngToContainerPoint(e.data.asset.location);
            // }else {
            //     pointPos = self._map.latLngToContainerPoint(marker.getLatLng());
            // }
            var iconSize = e.data.options.icon.options.iconSize;
            if (e.data.type == "context") {
                iconSize = [e.data.width, e.data.height];
            }
            var adj_x = iconSize[0] / 2;
            var adj_y = iconSize[1] / 2;

            var newCoords = {
                minX: pointPos.x - adj_x,
                minY: pointPos.y - adj_y,
                maxX: pointPos.x + adj_x,
                maxY: pointPos.y + adj_y,
                name: e.data.name,
                data: e.data
            };

            tmp.push(newCoords);

            //Redraw points
            self._drawMarker(e.data, pointPos, newCoords);
        });

        //Clear rBush & Bulk Load for performance
        self._markers.clear();
        self._markers.load(tmp);

        self.afterRedraw(self);
    },

    _initCanvas: function () {
        this._canvas = L.DomUtil.create(
            "canvas",
            "leaflet-canvas-icon-layer leaflet-layer"
        );
        this._fxcanvas = L.DomUtil.create(
            "canvas",
            "leaflet-canvas-icon-layer leaflet-layer"
        );
        // var originProp = L.DomUtil.testProp([
        //     "transformOrigin",
        //     "WebkitTransformOrigin",
        //     "msTransformOrigin"
        // ]);
        // this._canvas.style[originProp] = "50% 50%";

        var size = this._map.getSize();

        //Fix for pop-in
        //    var topLeft = this._map.containerPointToLayerPoint([-size.x,-size.y]);//this._canvas.width/2,this._canvas.height/2]);
        //     L.DomUtil.setPosition(this._canvas, topLeft);
        var topLeft = { x: -size.x, y: -size.y };
        L.DomUtil.setPosition(this._canvas, topLeft);
        L.DomUtil.setPosition(this._fxcanvas, topLeft);

        this._canvas.width = size.x * 3;
        this._canvas.height = size.y * 3;
        this.syncFXCanvas();
        // var topLeft = this._map.containerPointToLayerPoint([this._canvas.width/2,this._canvas.height/2]);
        // L.DomUtil.setPosition(this._canvas, topLeft);

        this._context = this._canvas.getContext("2d");
        this._fxcontext = this._fxcanvas.getContext("2d");

        var animated = this._map.options.zoomAnimation && L.Browser.any3d;
        L.DomUtil.addClass(
            this._canvas,
            "leaflet-zoom-" + (animated ? "animated" : "hide")
        );
        L.DomUtil.addClass(
            this._fxcanvas,
            "leaflet-zoom-" + (animated ? "animated" : "hide")
        );
    },

    syncFXCanvas() {
        this._fxcanvas.width = this._canvas.width;
        this._fxcanvas.height = this._canvas.height;
    },

    addOnClickListener: function (listener) {
        this._onClickListeners.push(listener);
    },

    addOnHoverListener: function (listener) {
        this._onHoverListeners.push(listener);
    },

    _executeListeners: function (event) {
        var self = this;
        if (!this._markers) return;
        if (!event.containerPoint) return;

        //console.warn("EVENT:",event.containerPoint,event);
        var me = this;
        var x = event.containerPoint.x;
        var y = event.containerPoint.y;

        if (me._openToolTip) {
            me._openToolTip.closeTooltip();
            delete me._openToolTip;
        }

        // function getDistance(origin, destination) {
        //     // var a = destination[0] - origin[0]; //x
        //     // var b = destination[1] - origin[0]; //y

        //     // var c = Math.sqrt(a * a + b * b);
        //     // return c;

        //     // return distance in meters
        //     var lon1 = toRadian(origin[1]),
        //         lat1 = toRadian(origin[0]),
        //         lon2 = toRadian(destination[1]),
        //         lat2 = toRadian(destination[0]);

        //     var deltaLat = lat2 - lat1;
        //     var deltaLon = lon2 - lon1;

        //     var a =
        //         Math.pow(Math.sin(deltaLat / 2), 2) +
        //         Math.cos(lat1) *
        //             Math.cos(lat2) *
        //             Math.pow(Math.sin(deltaLon / 2), 2);
        //     var c = 2 * Math.asin(Math.sqrt(a));
        //     //return c;
        //     var EARTH_RADIUS = 6371;
        //     return c * EARTH_RADIUS * 1000;
        // }
        // function toRadian(degree) {
        //     return (degree * Math.PI) / 180;
        // }
        //const distance = (x1, y1, x2, y2) => Math.hypot(x2 - x1, y2 - y1);
        // var distance = getDistance([lat1, lng1], [lat2, lng2])

        var offSet = 0;

        var minX = x - offSet;
        var minY = y - offSet;
        var maxX = x + offSet;
        var maxY = y + offSet;

        //console.warn(this._latlngMarkers);
        // var ret = this._markers.search({
        //     minX: minX,
        //     minY: minY,
        //     maxX: maxX,
        //     maxY: maxY
        // });
        // var zoomFactor = (17 / this._map.getZoom()) * 1.5;
        //var distanceThreshold = 0;// zoomFactor * 10;

        // var ret = knn(
        //     this._markers,
        //     minX,
        //     minY,
        //     2,
        //     (i) => {
        //         //var d = getDistance([minY,minX],[i.minY,i.minX]);
        //          var d = getDistance([minX, minY], [i.minX, i.minY]);
        //         //var d = distance(minX, minY, i.minX, i.minY);
        //         console.warn(`${i.name}:${d.toFixed(3)}:(${i.minX},${i.minY})`);
        //         return d < 1;
        //     },
        //     1
        // ); // return 10 nearest items around point [40, 40]
        var map = self._map;
        var mouseLatLng = map.containerPointToLatLng([minX, minY]);
        //console.warn("Mouse LatLng:",mouseLatLng);
        var latX = mouseLatLng.lng; //longitude is x
        var latY = mouseLatLng.lat; // latitude is y
        var pixelDistance = 25; //half size of icon

        var distanceThreshold =
            map.distance(
                map.containerPointToLatLng([0, 0]),
                map.containerPointToLatLng([1, 0])
            ) * pixelDistance;

        // var ret = knn(this._markers, minX, minY, 5, (i) => {

        var ret = knn(self._latlngMarkers, latX, latY, 5, (i) => {
            // var d = //getDistance([latY,latX],[i.minY,i.minX]);
            var d = map.distance([latY, latX], [i.minY, i.minX]);
            //console.warn(`${i.name}:${d.toFixed(3)}:(${i.minX},${i.minY})`);
            return d < distanceThreshold;
        });

        // var names = ret
        //     .map((i) => {
        //         // var d = getDistance([minX, minY], [i.minX, i.minY]);
        //         // var d = getDistance([minY,minX],[i.minY,i.minX]);
        //         //console.warn([latY,latX],[i.minY,i.minX]);
        //         // var d = distance([latY,latX],[i.minY,i.minX]);
        //         //var d = getDistance([latY,latX],[i.minY,i.minX]);
        //         var d= map.distance([latY,latX],[i.minY,i.minX]);
        //         // return `${i.name}:${i.minX},${i.minY}`
        //         return `${distanceThreshold}>${i.name}:${d.toFixed(3)}:(${i.minX},${i.minY})`;
        //     })
        //     .join("][");

        // console.warn(zoomFactor,minX, minY,`[${names}]`);
        //console.warn(minX, minY, `[${names}]`);

        // console.warn("Returned search results:",{minX: minX,
        //     minY: minY,
        //     maxX: maxX,
        //     maxY: maxY},ret);
        // if (event.type=='click'){
        //     console.warn("EVENT:",event.containerPoint,event);
        //     console.warn("MOUSE XY:",x,y,minX,maxX);
        //     console.warn(ret);
        // }

        if (ret && ret.length > 0) {
            me._map._container.style.cursor = "pointer";

            if (event.type === "click") {
                this._map.fire("onAssetClick", ret);
                // var hasPopup = ret[0].data.getPopup();
                // if (hasPopup) ret[0].data.openPopup();

                // Reorder:
                // this._markers.remove(ret[0]);
                // this._markers.insert(ret[0]);

                me._onClickListeners.forEach(function (listener) {
                    listener(event, ret[0]);
                });
                // event.preventDefault();
                // event.stopPropagation();
            }

            if (event.type === "mousemove") {
                var hasTooltip = ret[0].data.getTooltip();
                if (hasTooltip) {
                    me._openToolTip = ret[0].data;
                    ret[0].data.openTooltip();
                }

                //console.log("RET",ret[0].data.asset);
                if (ret[0].data.asset && ret[0].data.asset.type != "context") {
                    if (me._hoverAssetId != ret[0].data.asset.id) {
                        me._hoverAssetId = ret[0].data.asset.id;
                        me.redraw();
                    }
                } else {
                    me._hoverAssetId = "";
                }

                me._onHoverListeners.forEach(function (listener) {
                    listener(event, ret[0], me);
                });
            }
        } else {
            me._map._container.style.cursor = "";

            if (event.type === "click") {
                // console.warn("Clicked on nothing");
                this._map.fire("emptyClick");
            }
            if (event.type === "mousemove" && me._hoverAssetId != "") {
                me._hoverAssetId = "";
                me.redraw();
            }
        }
    }
});

//console.warn("CANVAS", new CanvasIconLayer());
L.canvasIconLayer = function (options) {
    return new CanvasIconLayer(options);
};
// };

//module.exports = layerFactory;
// export default {L,canvasIconLayerlayerFactory;
