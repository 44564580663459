/**
 * Core Management Module
 * ~~~~~~~~~~~~~~~~~~~~~~~~
 * Manages all client system core functionality
 *
 * @author Duncan Macfarlane
 * @version 1.0
 *
 */
import FirstAlertTheme from "../../config/themes/FirstAlertTheme.mjs";
import ContextDefaults from "../../common/defaults/Context.json";
import DeviceDefaults from "../../common/defaults/Device.json";
import ChatDefaults from "../../common/defaults/Chat.json";
const ChannelDefaults = JSON.parse(JSON.stringify(DeviceDefaults));
ChannelDefaults.rules = {};
import UserDefaults from "../../common/defaults/User.json";
export const Initialise = async (app) => {
    // Determine Server|Client:
    var isServer = false;

    app.mode = isServer ? "server" : "client";

    app.isServer = isServer;
    app.isClient = !isServer;

    console.info(`[CORE:CLI] Initialising Server Core...`);

    await (await import("./Cli_ConsoleManager.mjs")).install(app);

    await (await import("./Cli_ConfigManager.mjs")).install(app);

    //app.name = () => {console.warn("App config",app.config); return app.config.app.name;}
    app.name = () => app.config.app.name;
    // app.shortName = () => app.config.app.shortname;
    app.systemName = () => app.config.app.systemname;
    app.version = () => app.config.app.version;
    app.theme = FirstAlertTheme;

    app.console.initMsg(`Initialising ${app.name()} ${app.version()}`, "app");

    await (await import("./Cli_UtilityManager.mjs")).install(app);
    console.warn("Loading system manager:");
    await (await import("./Cli_SystemManager.mjs")).install(app);
    console.warn("Loaded system manager:");
    await (await import("./Cli_AudioManager.mjs")).install(app);

    await (await import("./Cli_WorkerManager.mjs")).install(app);

    await (await import("./Cli_ServerManager.mjs")).install(app);

    await (await import("./Cli_APIManager.mjs")).install(app);

    await (await import("./Cli_SecurityManager.mjs")).install(app);

    await (await import("./Cli_SceneManager.mjs")).install(app);

    await (await import("./Cli_UIManager.mjs")).install(app);

    await (await import("./Cli_AssetsManager.mjs")).install(app);

    await (await import("./Cli_UserManager.mjs")).install(app);

    await (await import("./Cli_ContextManager.mjs")).install(app);
    await (await import("./Cli_HelpManager.mjs")).install(app);
    await (await import("./Cli_DialogManager.mjs")).install(app);

    // Finally - load state manager
    await (await import("./Cli_StateManager.mjs")).install(app);

    app.core = {
        defaults: {
            Device: DeviceDefaults,
            Camera: ChannelDefaults,
            Context: ContextDefaults,
            User: UserDefaults,
            Chat: ChatDefaults
        },

        /**
         * Perform initial startup procedure
         */
        boot: () =>
            new Promise((resolve) => {
                // Start the system server:
                app.console.initMsg(
                    `Booting FirstAlert ControlPanel...`,
                    "core"
                );
                //app.workers.init();
                app.server.start();
                resolve();
            })
    };
    app.core.defaults.user = app.core.defaults.User;
    app.core.defaults.context = app.core.defaults.Context;
    app.core.defaults.device = app.core.defaults.Device;
    app.core.defaults.nvr = app.core.defaults.Device;
    app.core.defaults.ipcamera = app.core.defaults.Device;
    app.core.defaults.camera = app.core.defaults.Camera; 
    app.core.defaults.chat = app.core.defaults.Chat; 
    

    app.is = {
        nvr: (type) => ({ nvr: 1 }[type] ? true : false),
        camera: (type) => ({ camera: 1, ipcamera: 1 }[type] ? true : false),
        alertable: (type) => ({ camera: 1, ipcamera: 1 }[type] ? true : false)
    };

    // Reset app
    app.reset = () => {
        // Reset user:
        //app.ui.navdrawer.hide();
        //app.user.reset();
        app.stateManager.reset();
    };

    app.console.initMsg(`ControlPanel Core Manager Loaded ✓`, "app");
    return app;
};
