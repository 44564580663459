/**
 * Client App - Core loader module
 * Provides a default layuout for the modules
 *
 * @author Duncan Macfarlane
 * @version 1.0
 *
 */

 const app = {
    onBootQ: [],
    onBoot(cb) {
        this.onBootQ.push(cb);
    },
};
// Load Core:
import { Initialise } from "./Cli_CoreManager.mjs";

async function init() {
    await Initialise(app);
    
    // Boot request:
    app.core.boot().then(() => {
        if (app.onBootQ.length > 0) {
            app.onBootQ.forEach((onBootCB) => {
                onBootCB();
            });
        }
    });
}
init();

export default app;
