import app from "../../core/Cli_FirstAlertApp.mjs";

const FirstAlertVue = {app:app};

console.info("Initialising FirstAlert.Vue Kernel...");//,app);

FirstAlertVue.install = (Vue, options) => {
    console.info("Installing FirstAlert...");//, options);
    app.Vue = Vue;
    app.eventbus = new Vue();

    Vue.prototype.$app = app;
    app.onBoot(()=>{
       // console.warn("On boot");
        Vue.prototype.$console = app.console;
        Vue.prototype.$eventbus = app.eventbus;
        Vue.prototype.$utils = app.utils;
        Vue.prototype.$system = app.system;
        Vue.prototype.$config = app.config;
        Vue.prototype.$server = app.server;
        Vue.prototype.$security = app.security;
        Vue.prototype.$user = app.user;
        Vue.prototype.$scene = app.scene;
        Vue.prototype.$state = app.state;
        Vue.prototype.$ui = app.ui;
        Vue.prototype.$hud = app.ui.hud;
        Vue.prototype.$map = app.ui.map;
        Vue.prototype.$assetView = app.ui.hud.assetView;
        Vue.prototype.$assetBrowser = app.ui.hud.assetBrowser;
        Vue.prototype.$eveBtn = app.ui.eveBtn;
        Vue.prototype.$ws = app.websocket;
        Vue.prototype.$context = app.context;
        Vue.prototype.$contexts = app.contexts;
        Vue.prototype.$assets = app.assets;
        Vue.prototype.$activeAsset = app.activeAsset;
        Vue.prototype.$help = app.help;
        Vue.prototype.$dialog = app.dialog;
        //app.server.start();
        
        // Check user session:
        app.console.infoMsg("Checking session...");
        app.user.refresh().then(user => {
            if (user) {
                app.websocket.connect();
            }
        });

        app.eventbus.$on('serverstatuschanged',(val)=>{
            app.console.infoMsg("Server status changed "+val);
        });
        // app.ui.init(Vue);
        
    });
    
};
export default FirstAlertVue;
