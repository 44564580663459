<template>
    <v-app :class="$ui.getRedMode()?'redmode':''" >
        <SideMenu v-if="$user.loggedIn()" />

        <v-main class="pa-0" style="align-items: center" >
            <v-component :is="$state.scene" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave"></v-component>
        </v-main>
        <NotificationManager />
        <ConfirmDlg ref="confirm" />
    </v-app>
</template>

<script>
import Cookies from "js-cookie";
import ConfirmDlg from "./components/widgets/ConfirmDlg.vue";
// const beforeUnloadListener = (event) => {
//     event.preventDefault();    
//     return (event.returnValue = "Are you sure you want to exit?");
// };

let I, app;
export default {
    name: "App",
    created() {
        I = this;
        app = I.$app;
        document.documentElement.addEventListener('mouseleave', () =>  I.onMouseLeave())
        document.documentElement.addEventListener('mouseenter', () =>  I.onMouseEnter())
        // window.onpopstate = function(event) {
        //     event.preventDefault(); 
        //     // Check 
        //     app.dialog.confirm
        //             .open(
        //                 `Leave ${app.name()}?`,
        //                 `Are you sure you want to leave?`
        //             )
        //             .then((confirmed) => {
        //                 if (confirmed){
        //                     removeEventListener("beforeunload", beforeUnloadListener, {capture: true});
        //                 }
        //                 history.back();;
        //             });
        //     //alert('browser-back');
        // };
    },
    async mounted() {
        this.$root.$confirm = this.$refs.confirm.open;
        app.dialog.confirm.open = this.$refs.confirm.open;
        // Check preload cookie
        var c = Cookies.get("preload");

        if (c) {
            // Preload:
            console.warn("PRELOAD COOKIE:",c);

            try {
                c = JSON.parse(c);
            } catch (e) {
                app.console.errorMsg(
                    `Error trying to parse PRELOAD cookie:${e}`
                );
            }

            if (c) {
                // console.warn("PRELOAD:",c);
               
               
                // Delete the cookie:

                Cookies.remove("preload");

                if (c.device) {
                    // Set active asset:
                    // Get channel?
                    var deviceID = c.device;
                    var channelIndex = 0;
                    var parts = c.device.split("CHAN");
                    if (parts[1]) {
                        deviceID = parts[0];
                        channelIndex = +parts[1];
                    }
                    //console.warn("Getting the device!",deviceID);

                    async function onReady(){
                        let device = await app.devices.get(deviceID); 
                        if (device && device.ok){
                            device = device.asset;

                            if (parts[1]) {
                                device = device.channels[channelIndex];                                
                            }
                            device.id = c.device;

                            //console.warn("PRELOAD DEVICE!",device); 

                            if (app.map.status.ready){
                                //setTimeout(() => {
                                    app.ui.hud.assetView.select(devices);
                                //}, 50);
                            }else{
                                app.eventbus.$once("map-ready", () => {
                                    //console.warn("PRELOAD set active asset:",result.asset);
                                    //setTimeout(() => {
                                        app.ui.hud.assetView.select(device);
                                    //}, 50);
                                });
                            }
                        }else{
                            console.error(`Device could not be preloaded! Does not exist? [${deviceID}]`);
                        }                       
                            
                    }

                    if (app.state.wsconnected){
                        onReady();
                    }else{
                        app.eventbus.$once(`ws-ready`,(event)=>{
                             onReady();
                        });  
                    }
                   
                    // app.devices.get(deviceID).then((result) => {
                    //     console.warn("Got the device?",result);
                        
                    //     if (result.ok) {
                    //         if (parts[1]) {
                    //             result.asset =
                    //                 result.asset.channels[channelIndex];
                    //         }
                    //         result.asset.id = c.device;

                    //         if (app.map.status.ready){
                    //             setTimeout(() => {
                    //                 app.ui.hud.assetView.select(result.asset);
                    //             }, 500);
                    //         }else{
                    //             app.eventbus.$once("map-ready", () => {
                    //             //console.warn("PRELOAD set active asset:",result.asset);
                    //             setTimeout(() => {
                    //                 app.ui.hud.assetView.select(result.asset);
                    //             }, 500);
                    //         });
                    //         }
                    //         //app.activeAsset.set(result.asset);
                            
                    //         //console.warn("Fetched the preloaded asset:",result);
                    //     }
                    // });
                }
            }
        }
        this.$nextTick(() => {
            // console.warn(this.$app);
            // this.$server.start();
            
        });
    },
    methods: {
        onMouseEnter() {
            //app.console.infoMsg(`User moved the mouse onto the page`);
            // if (!this.addedUnload) {
            //     this.addedUnload = true;
            //     addEventListener("beforeunload", beforeUnloadListener, {
            //         capture: true
            //     });
            // }
            // Check connectivity:
            //console.warn("Websockets connected?",app.websocket.checkConnected);
            app.websocket.checkConnected();
        },
        onMouseLeave() {
           // app.console.infoMsg(`User moved the mouse off the page`);
        }
    },

    components: {
        LobbyScene: () => import("@/components/scenes/LobbyScene"),
        MapViewScene: () => import("@/components/scenes/MapViewScene"),
        NotificationManager: () =>
            import("@/components/widgets/NotificationManager"),
        SideMenu: () => import("@/components/widgets/SideMenu"),
        ConfirmDlg
    },
    computed: {
        loggedIn: () => false
    },

    data: () => ({
        addedUnload: false
    })
};
</script>
