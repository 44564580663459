export const FirstAlertTheme = {
    colours: {
        blue: "#00AAFF",
        darkBlue: "#01124F",
        lightBlue: "#E6F0FF",
        white: "#FFFFFF",
        black: "#000000",
        lightGrey: "#999999",
        darkGrey: "#666666",
        grey: "#333333"
    }
};

export default FirstAlertTheme;