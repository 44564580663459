/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
    //console.warn(`${process.env.BASE_URL}service-worker.js`);
    register(`${process.env.BASE_URL}fa-service-worker.js`, {
        ready() {
            console.log(
                "App is being served from cache by a service worker.\n" +
                    "For more details, visit https://goo.gl/AFskqB"
            );
        },
        registered(registration) {
            console.log("Service worker has been registered.");

        },
        cached() {
            console.log("Content has been cached for offline use.");
        },
        updatefound() {
            console.log("New content is downloading.");
        },
        
        updated(registration) {
            console.log("New content is available; please refresh.");
            console.warn('REG?',registration);
            if (window.confirm("New version available! OK to refresh?")){
                const pushState = window.history.pushState;
                const worker = registration.installing || registration.waiting;
                worker.postMessage('skipWaiting');
                setTimeout(()=>{
                    window.reload();
                },100);
                // window.history.pushState = function () {
                //   // make sure that the user lands on the "next" page
                //   pushState.apply(window.history, arguments);
                  
                //   // makes the new service worker active
                //   worker.postMessage('skipWaiting');
                // };           
            }
            
        },
        offline() {
            console.log(
                "No internet connection found. App is running in offline mode."
            );
        },
        error(error) {
            console.error("Error during service worker registration:", error);
        }
    });
}
