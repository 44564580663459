<template>
    <v-dialog
        v-model="dialog"
        :max-width="options.width"
        @keydown.esc="cancel"
        @keyup="onKey"
    >
        <v-card :class="`glass-panel-shaped ${options.type}-panel`">
            <v-toolbar dark :class="`${options.type}-panel`" dense>
                <v-icon v-if="options.icon !== ''">{{ options.icon }}</v-icon>
                <v-toolbar-title class="white--text">{{
                    title
                }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text
                class="text-center pa-5"
                v-show="!!message"
                v-html="message"
            ></v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn
                    ref="yesBtn"
                    :color="options.activeBtnTxtColor"
                    @click="agree"
                    text
                    >{{ options.yeslabel }}</v-btn
                >
                <v-btn
                    ref="noBtn"
                    :color="options.btnTxtColor"
                    text
                    @click="cancel"
                    >{{ options.nolabel }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
    props: {
        autofocusYes: { type: Boolean, default: true },
        autofocusNo: { type: Boolean, default: false }
    },
    mounted() {
        //console.log(`!!!!!!CONFIRM DIALOG MOUNTED!!!!!!`);
    },
    beforeDestroy() {},
    data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,

        options: {
            type: "info", // error|info|warn
            icon: "mdi-help",
            color: "primary",
            yeslabel: "Yes",
            nolabel: "Cancel",
            width: 290,
            activeBtnTxtColor: "white",
            btnTxtColor: "white darken-1"
        }
    }),
    methods: {
        onKey(e) {
            console.log("Key was pressed:", e);
        },
        open(title="", message="", options={}) {
            this.dialog = true;
            this.title = title;
            this.message = message;
            let {
                    icon= "mdi-help-circle",
                    color= "primary",
                    yeslabel= "Yes",
                    nolabel= "Cancel",
                    width= 290,
                    type= "info"
                } = options;
            this.options = {...options,icon,color,yeslabel,nolabel,width,type};
            // this.options = Object.assign(
            //     this.options,
            //     {
            //         icon,//: "mdi-help-circle",
            //         color,//: "primary",
            //         yeslabel,//: "Yes",
            //         nolabel,//: "Cancel",
            //         width,//: 290,
            //         type,//: "info"
            //     },
            //     options
            // );

            // switch (this.options.type) {
            //     case "info":
            //         this.options.btnTxtColor = "white";
            //         this.options.activeBtnTxtColor = "white darken1";
            //         break;
            //     case "warn":
            //         break;
            //     case "error":
            //         break;
            // }
            //console.log(`Autofocus?`,{yes:this.autofocusYes,no:this.autofocusNo});
            if (this.autofocusYes) {
                setTimeout(() => {
                   // console.log("YES BTN FOCUS:",this.$refs.yesBtn);
                   // console.log("YES BTN FOCUS EL:",this.$refs.yesBtn.$el);
                    this.$refs.yesBtn.$el.focus();
                },100);
            } else if (this.autofocusNo) {
                setTimeout(() => {
                    this.$refs.noBtn.$el.focus();
                },100);
            }
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        agree() {
            this.resolve(true);
            this.dialog = false;
        },
        cancel() {
            this.resolve(false);
            this.dialog = false;
        }
    }
};
</script>
